import React from 'react';
import SplitText from 'react-pose-text';
import wedding from '../images/wedding.jpg';

const charPoses = {
  exit: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 60
  }
};

const Page3 = ({progress}) => {
  return (
    <div className="page page2">
      <h1>
      <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
        Got Married!! Woohoo!! One of the happiest days of my life :)
      </SplitText>
      <img src={wedding} 
        style={{
          maxWidth: '100%',
          marginTop: '20px',
          opacity: progress*3,
        }}
      />
      </h1>
    </div>
  );
};

export default Page3;