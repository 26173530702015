import React from 'react';
import SplitText from 'react-pose-text';


const charPoses = {
  exit: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 60
  }
};

const Page2 = ({progress}) => {
  return (
    <div className="page page2">
      <h1>
      <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
        This has been by far one of our hardest years but at the same time also one of our most rewarding! Lets take a look at our year... 
      </SplitText>
      </h1>
    </div>
  );
};

export default Page2;