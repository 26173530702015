import React from 'react';
import SplitText from 'react-pose-text';

const charPoses = {
  exit: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 60
  }
};

const Page6 = ({progress}) => {
  return (
    <div className="page page2">
      <h1 style={{fontSize: '20px'}}>
      <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
        From getting married to buying a house, I feel like this year we grew so much as adults and as partners. Now more than ever I feel like we are a team
        and no matter the struggle or hardship, we can always find a way through. I know you say that I am the more calm one but Im only that way because I 
        have you to love and fight for. I hope you enjoyed this little recap and I cant wait to see whats in store this year. Muah! 
      </SplitText>
      </h1>
    </div>
  );
};

export default Page6;