import React from 'react';
import SplitText from 'react-pose-text';
import carlos from '../images/carlos.jpg';

const charPoses = {
  exit: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 60
  }
};

const Page7 = ({progress}) => {
  return (
    <div className="page page2">
      <h1>
      <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
        Your Loving Husband, Carlos
      </SplitText>
      <img src={carlos} 
        style={{
          maxHeight: '300px',
          marginTop: '20px'
        }}
      />
      </h1>
    </div>
  );
};

export default Page7;