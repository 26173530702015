import React from 'react';
import SplitText from 'react-pose-text';


const charPoses = {
  exit: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30
  }
};

const Page1 = ({progress}) => {
  const text = "I love you with all my heart!";
  return (
    <div className="page page1">
      <h1>
      <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
        Happy Anniversary Beautiful Squishy!
      </SplitText>
      </h1>
      <h2 className="page1-text"
        style= {{
          transition: "all .5s ease-in-out",
          marginTop: (20 - 20*progress*2) + 'px',
          opacity: progress*2,
          position: 'relative'
        }}
      >{text}</h2>
      <div className="heart"
        style={{
          marginTop: (20 - 20*progress*2) + 'px',
          opacity: progress*2,
        }}
      >&hearts;</div>
    </div>
  );
};

export default Page1;