import React from 'react';
import SplitText from 'react-pose-text';
import chillin from '../images/chillin.jpg';

const charPoses = {
  exit: { opacity: 0, y: 20 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 60
  }
};

const Page4 = ({progress}) => {
  return (
    <div className="page page2">
      <h1 style={{fontSize: '20px'}}>
      <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
        Save Save Save! We spent most of our year saving for a home and staying home like bums! It was tough not being able to spend but we always knew it would be worth it.

      </SplitText>
      <img src={chillin} 
        style={{
          maxHeight: '300px',
          marginTop: '20px',
          opacity: progress*3,
        }}
      />
      </h1>
    </div>
  );
};

export default Page4;