import React from 'react';
import logo from './logo.svg';
import './App.css';
import {useStickyroll} from "@stickyroll/hooks";
import Page1 from './pages/page1'
import Page2 from './pages/page2';
import Page3 from './pages/page3';
import Page4 from './pages/page4';
import Page5 from './pages/page5';
import Page6 from './pages/page6';
import Page7 from './pages/page7';

const headlines = [
  'adsf',
  "asdf",
  "Hello Stickyroll!",
  "Let's continue with the next lesson!",
  "2313",
  'asdfaf'
];

const App = () => {
  const [wrapper, {height, currentPage, pageCount, pageIndex, progress}] = useStickyroll({
      pages: headlines
  });
  return (
      <div ref={wrapper} style={{height}}>
          <div className="stickyroll-content">
              {pageIndex === 0 && <Page1 progress={progress} />}
              {pageIndex === 1 && <Page2 progress={progress} />}
              {pageIndex === 2 && <Page3 progress={progress} />}
              {pageIndex === 3 && <Page4 progress={progress} />}
              {pageIndex === 4 && <Page5 progress={progress} />}
              {pageIndex === 5 && <Page6 progress={progress} />}
              {pageIndex === 6 && <Page7 progress={progress} />}
          </div>
      </div>
  );
};

export default App;
